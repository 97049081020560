html {
    font-family: "Noto Sans TC", Microsoft JhengHei, sans-serif;
    font-weight: 400;
    letter-spacing: 0.8px;
}

body {
    font-family: "Noto Sans TC", Microsoft JhengHei, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    letter-spacing: 0.8px;
}

input,
button,
select {
    letter-spacing: 0.8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}
