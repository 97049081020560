.editor {
    &-mobile-none {
        @media (max-width: 767px) {
            display: none !important;
        }
    }
    &-desktop-none {
        @media (min-width: 768px) {
            display: none !important;
        }
    }
    &-max-w-100 {
        max-width: 100% !important;
    }

    &-max-w-mobile-100 {
        @media (max-width: 767px) {
            max-width: 100% !important;
        }
    }
}
