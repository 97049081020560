// below ipad width

@media screen and (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
        // box-shadow: inset 0 0 5px #dddddd;
        // border-left: 1.5px solid transparent;
        // border-right: 1.5px solid transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #9e9e9e;
        border-radius: 2px;
    }
}
