input,
textarea {
    &::-moz-placeholder {
        color: rgba(31, 31, 31, 0.5);
    }

    &:-ms-input-placeholder {
        color: rgba(31, 31, 31, 0.5);
    }

    &::-webkit-input-placeholder {
        color: rgba(31, 31, 31, 0.5);
    }
}

select {
    color: rgba(31, 31, 31, 0.5);
}

input,
textarea,
select {
    -webkit-appearance: none;
    outline: none;
}
