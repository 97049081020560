// 引入Bootstrap v4.6 Grid, Reboot CSS
// mixins, variable 雖也為全域樣式，但必須在component 內引入才能使用，故置於shared 資料夾

// vendors 插件
@import "./styles/vendors/bootstrap-reboot";
@import "./styles/vendors/bootstrap-grid";

@import "./styles/components/editor";

// base 基底
@import "./styles/base/typography";
@import "./styles/base/scrollbar";
@import "./styles//base/form";

// utils 功能型樣式
@import "./styles/utils/helpers";

// swiper
@import "~swiper/css";
@import "~swiper/css/pagination";

//換swiper套件
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

//全域body 樣式
body {
  overflow-x: hidden;
  &::before {
        @media (min-width: 1200px) {
            content: "";
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 70px;
            background-image: url("/assets/images/background/main_mask.svg");
            background-repeat: no-repeat;
            background-position: 0 30px;
            background-size: 1540px;
            z-index: -2;
            overflow: visible;
        }

        @media (min-width: 1540px) {
            background-size: contain;
            top: 45px;
        }

        @media (min-width: 1680px) {
            top: 35px;
        }

        @media (min-width: 1920px) {
            top: 0px;
        }
    }
}

//格線系統樣式
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;

        padding-left: 0;
        padding-right: 0;
    }
}

.swiper {
  padding: 0 0 30px !important;
  width: 100%;
  height: 100%;

  &-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-pagination-bullet {
    border-radius: 4px;
    width: 12px;
    height: 4px;
    background-color: #afafaf;
    opacity: 1;

    &-active {
      background-color: #404040;
    }
  }
}
