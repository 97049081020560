@mixin list-loop($className, $styleName, $size: "", $unit: "px", $offset: 5, $max: 50) {
    $i: 0;
    $unitMark: "";
    @if ($unit== "px") {
        $unitMark: "p";
    } @else if($unit== "%") {
        $unitMark: "";
    } @else if($unit== "rem") {
        $unitMark: "r";
    }

    @while $i<=$max {
        @if ($size== "xl") {
            @media screen and (max-width: 1200px) {
                #{$className + "-" + $i + $unitMark} {
                    #{$styleName}: #{$i + $unit};
                }
            }
        } @else if($size== "lg") {
            @media screen and (max-width: 992px) {
                #{$className + "-" + $i + $unitMark} {
                    #{$styleName}: #{$i + $unit};
                }
            }
        } @else if($size== "md") {
            @media screen and (max-width: 768px) {
                #{$className + "-" + $i + $unitMark} {
                    #{$styleName}: #{$i + $unit};
                }
            }
        } @else if($size== "sm") {
            @media screen and (max-width: 576px) {
                #{$className + "-" + $i + $unitMark} {
                    #{$styleName}: #{$i + $unit};
                }
            }
        } @else {
            #{$className + "-" + $i + $unitMark} {
                #{$styleName}: #{$i + $unit};
            }
        }

        $i: $i + $offset;
    }
}

// margin related class
@include list-loop(".marginT", "margin-top");
@include list-loop(".marginB", "margin-bottom");
@include list-loop(".marginL", "margin-left");
@include list-loop(".marginR", "margin-right");
@include list-loop(".marginXY", "margin");

// padding related class
@include list-loop(".paddingT", "padding-top");
@include list-loop(".paddingB", "padding-bottom");
@include list-loop(".paddingL", "padding-left");
@include list-loop(".paddingR", "padding-right");
@include list-loop(".paddingXY", "padding");

// width related class
@include list-loop(".width", "width", "", "%", 10, 100);

// height related class
@include list-loop(".height", "height", "", "%", 10, 100);
